import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Order Activation";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  application: {
    order: {
      api: {
        headers: {
          "Content-Type": "application/json",
          Authorization: "",
          moduleCode: "WGMB",
        },
        endpoints: {
          headers: {
            'Authorization': "",
          },
          activate: {
            doc: "http://tr4ns10.tr4ns.com/Westfield-BulkOrder/orders/{orderId}/activate",
            url: setup.setup.api.westfield_v1.url[environment] + "/orders/",
            method: "POST",
          },
        },
      },
      objects: {
        orderId: {  // Changed from 'name' to 'orderId'
          name: "orderId",
          initial: "",
          type: "text",
          label: "Order Id",
          helpText: false,
          rules: false,
        },
      },
    },
  },
  routes: {
    label: "Activate",
    prefix: "/orders/activate",
  },
  setup,
  environment,
};

export default config;
