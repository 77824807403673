import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { Row, Col } from 'reactstrap';

/* packages */
import Fetch from '../../util-fetch';
import Button from '../../util-mui-buttons';
import Input from '../../util-mui-inputs';
import useForm from '../../util-hook-useform';
import validation from '../../util-inputs-validation';
import Breadcrumbs from '../../util-mui-breadcrumbs';
import DesignSection from '../../util-mui-design-section';

import { LocalStorage } from '../../lib-atomic-functions';
/* packages */

/* stores */
import OrderActivationStore from '../../app-module-order-container/stores/activate';
/* stores */

/* loader */
import OrderActivationLoader from '../loader';
/* loader */

import CONFIG from '../config';

const Default = observer((props) => {

  const { login } = LocalStorage.getLS();
  const { token } = login;

  const config = CONFIG.application.order.objects;

  /* states */
  const OrderActivationContext = useContext(OrderActivationStore);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [successMessage, setSuccessMessage] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const OrderActivation = async (body) => {
    try {
      setIsSubmitting(true);
      const parsedBody = JSON.parse(body);
      const currentOrderId = parsedBody.orderId; // Store orderId
      OrderActivationContext.setLoading({ loading: true });
      CONFIG.application.order.api.headers.Authorization = token;
      const orderActivationFetch = await Fetch({
        url: CONFIG.application.order.api.endpoints.activate.url + currentOrderId + '/activate',
        config: {
          method: CONFIG.application.order.api.endpoints.activate.method,
          headers: CONFIG.application.order.api.headers,
          body
        }
      });
      OrderActivationContext.setLoading({
        loading: false,
        status: orderActivationFetch[0],
        data: orderActivationFetch[1]
      });
      if (!orderActivationFetch[0]) {
        const errorMsg = orderActivationFetch[1] && orderActivationFetch[1].outcomeDeveloperMessage 
          ? <>{orderActivationFetch[1].outcomeDeveloperMessage} - <strong>{currentOrderId}</strong></>
          : <>Activation failed - <strong>{currentOrderId}</strong></>;
        setErrorMessage(errorMsg);
        setSuccessMessage('');
      } else {
        setErrorMessage('');
        setSuccessMessage(<>Order has been successfully activated - <strong>{currentOrderId}</strong></>);
      }
    } catch (error) {
      OrderActivationContext.setLoading({
        loading: false,
        status: false,
        data: null
      });
    } finally {
      setIsSubmitting(false);
      handleValue('orderId', '');
    }
  }

  /* handles */
  const handleActivateOrder = async () => {
    await OrderActivation(JSON.stringify(values))
  }
  /* handles */

  const initials = (config) => {
    let values = {};
    for (const key in config) { values[key] = config[key].initial; }
    return values;
  }

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleValue,
  } = useForm(handleActivateOrder, validation, config, initials(config));

  const orderId = { ...config.orderId, errors: errors.orderId, value: values.orderId, handleChange };
  if (OrderActivationContext.loading) return <OrderActivationLoader />

  return <Row>
    <Col xs="12" sm="12" md="12" lg="12" xl="12">
      <Breadcrumbs>
        <Link to={"/orders"}><small>Home</small></Link>
        <small>Activate</small>
      </Breadcrumbs>
      <DesignSection.Title>Activate Order</DesignSection.Title>
    </Col>
    <Col xs="12" sm="12" md="6" lg="3" xl="3">
      <form onSubmit={handleSubmit} noValidate>
        <Input.Text {...orderId} />
        {errorMessage && (
          <div className="text-danger mt-2">
            {errorMessage}
          </div>
        )}
        {successMessage && (
          <div className="text-success mt-2">
            {successMessage}
          </div>
        )}
        <section className="space-standard-top space-standard-bottom">
          <Button.Normal disabled={isSubmitting}>
            {isSubmitting ? 'Activating...' : 'Activate'}
          </Button.Normal>
        </section>
      </form>
    </Col>
  </Row>

});

export default Default;
