/* reporting module */
import PageLanding from "../../../app-module-reporting-page-landing";
import ReportingPage19 from "../../../app-module-reporting-page-19";
import ReportingPage20 from "../../../app-module-reporting-page-20";
import ReportingPage21 from "../../../app-module-reporting-page-21";

import ReportingLanding19 from "../../../app-module-reporting-landing-19";
import ReportingLanding20 from "../../../app-module-reporting-landing-20";
import ReportingLanding21 from "../../../app-module-reporting-landing-21";

import ReportingEmptyLanding from "../../../app-module-reporting-landing-empty";
import Reporting404 from "../../../app-module-admin-landing-404";

/* company module */
import CompanyLanding from "../../../app-module-company-landing";
import CompanyList from "../../../app-module-company-list";
import CompanySearch from "../../../app-module-company-search";
import CompanyCreate from "../../../app-module-company-create";
import CompanyEdit from "../../../app-module-company-edit";
import CompanyApprove from "../../../app-module-company-approve";
import CompanyDecLine from "../../../app-module-company-decline";
import CompanyDetail from "../../../app-module-company-detail";
import CompanyContactsCreate from "../../../app-module-contact-create";
import CompanyContactsEdit from "../../../app-module-contact-edit";
import LandingSuccessCompanyCreate from "../../../app-module-company-landing-success-create";
import LandingSuccessCompanyApprove from "../../../app-module-company-landing-success-approve";
import LandingSuccessCompanyDecline from "../../../app-module-company-landing-success-decline";
// import CompanyApprove from "../../../app-module-company-page-approve";
// import CompanyDecline from "../../../app-module-company-page-decline";

/* orders */
import OrderPageLanding from "../../../app-module-order-page-landing";
import OrderPageList from "../../../app-module-order-page-list";
import OrderPageDetail from "../../../app-module-order-page-detail";
import OrderPageConfirmation from "../../../app-module-order-page-confirmation";
import OrderLandingConfirmation from "../../../app-module-order-landing-success-confirmation";
import OrderPageApprove from "../../../app-module-order-page-approve";
import OrderLandingApprove from "../../../app-module-order-landing-success-approve";
import OrderPageDecline from "../../../app-module-order-page-decline";
import OrderLandingDecline from "../../../app-module-order-landing-success-decline";
import OrderPageDelete from "../../../app-module-order-page-delete";
import OrderLandingDelete from "../../../app-module-order-landing-success-delete";
import OrderPageActivationSending from "../../../app-module-order-page-activation-sending";
import OrderLandingActivationSending from "../../../app-module-order-landing-success-activation-sending";
import OrderPageCreate from "../../../app-module-order-page-create";
import OrderPageActivate from "../../../app-module-order-page-activation";
import OrderLandingCreate from "../../../app-module-order-landing-success-create";
import OrderPageEdit from "../../../app-module-order-page-edit";
import OrderLandingEdit from "../../../app-module-order-landing-success-edit";
import OrderPageSearch from "../../../app-module-order-page-list-search";
import OrderPagePDF from "../../../app-module-order-page-pdf";

/* orders */
const orderModules = [
  OrderPageLanding,
  OrderPageList,
  OrderPageDetail,
  OrderPageConfirmation,
  OrderLandingConfirmation,
  OrderPageApprove,
  OrderLandingApprove,
  OrderPageDecline,
  OrderLandingDecline,
  OrderPageDelete,
  OrderLandingDelete,
  OrderPageActivationSending,
  OrderLandingActivationSending,
  OrderPageCreate,
  OrderPageActivate,
  OrderLandingCreate,
  OrderPageEdit,
  OrderLandingEdit,
  OrderPageSearch,
  OrderPagePDF,
];

/* company */
const companyModules = [
  CompanyLanding,
  CompanyList,
  CompanySearch,
  CompanyCreate,
  CompanyEdit,
  CompanyApprove,
  CompanyDecLine,
  CompanyDetail,
  CompanyContactsCreate,
  CompanyContactsEdit,
  LandingSuccessCompanyCreate,
  LandingSuccessCompanyApprove,
  LandingSuccessCompanyDecline,
  // CompanyApprove,
  // CompanyDecline,
];

/* reporting */
const reportingModules = [
  PageLanding,
  ReportingPage19,
  ReportingPage20,

  ReportingLanding19,
  ReportingLanding20,

  ReportingEmptyLanding,
];

const modules = {
  company: companyModules,
  reporting: reportingModules,
  orders: orderModules,
  product: [],
};

export default modules;
